$landing-pg-intro-color:linear-gradient(90deg,#e52e71,#ff8a00);
$custom-tab-color: linear-gradient(to top ,#1AA48B ,#1AA48B);
$nav-link-color: #1aa48bac;
$font-folor: white;
$footer-bg-color:linear-gradient(to top ,#1AA48B ,#1AA48B);
$footer-bg-opacity:100%;
$button-color:#1AA48B;
$font-style:Ubuntu , sans serif;
$font-color:rgb(12, 0, 144);
$innerCarrerCardBg:#1AA48B;
$formHeaderBg:#1AA48B;