@import "./Variables/variables.scss";

// ===================================Global====================================>

body {
  background: white;
  font-family: $font-style;
}

.MainHeadings {
  color: white;
  text-shadow: -2px 2px 3px #000;
  font-weight: 900;
  letter-spacing: 1px;
}
.MainHeadings_sub {
  color: white;
  text-shadow: -2px 2px 3px #000;
  font-weight: 300 !important;
  letter-spacing: 1px;
}

.SubHeadings {
  color: white;
  font-family: Play !important;
  text-shadow: 1px 1px 2px #054545;
  font-weight: 300;
  letter-spacing: 2px;
}

.SubHeadingsBlack {
  color: rgb(0, 0, 0);
  font-family: Play !important;
  text-shadow: 1px 1px 3px #00000067;
  font-weight: 300;
  letter-spacing: 2px;
}

.InnerMianHaddings {
  color: #000000;
  text-shadow: 1px 1px 3px #00000067;
  font-weight: 900;
}

.Sub-Sub-Hadding {
  color: #000000;
  font-family: Play !important;
}

.fs-cus-1 {
  font-size: 15px;
}
.fs-cus-2 {
  font-size: 15px;
}

// .MainHeadings {
//   color: white;
//   text-shadow: -2px 2px 3px #000;
//   font-weight: 900;
//   letter-spacing: 1px;
// }

// .SubHeadings {
//   color: white;
//   font-family: Play !important;
//   text-shadow: 1px 1px 2px #054545;
//   font-weight: 300;
//   letter-spacing: 2px;
// }

// .Sub-Sub-Hadding {
//   color: white;
//   font-family: Play !important;
// }

//=========================COUNTER CSS =============================================>

.CounterSubHeading {
  color: rgb(0, 0, 0);
  font-family: Play !important;
  text-shadow: 1px 1px 2px rgba(91, 91, 91, 0.299);
  font-weight: 300;
  letter-spacing: 2px;
}

.CounterHeading {
  color: rgb(0, 0, 0);
  text-shadow: -2px 2px 3px rgba(91, 91, 91, 0.299);
  font-weight: 900;
  letter-spacing: 1px;
}

//======================================Card Carousel=========================>

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

// .container {
//   position: relative;
//   width: 1100px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   flex-wrap: warp;
//   padding: 30px;
// }

.container .cards {
  position: relative;
  max-width: 300px;
  height: 178px;
  background-color: #fff;
  margin: 30px 10px;
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 10px #1aa48b;
  transition: 0.3s ease-in-out;
  border-radius: 15px;
}

.container .cards:hover {
  height: 330px;
}

.container .cards .image {
  position: relative;
  width: 260px;
  height: 260px;
  top: -40%;
  left: 8px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.container .cards .image img {
  max-width: 100%;
  border-radius: 15px;
}

.container .cards .content {
  position: relative;
  top: -140px;
  padding: 10px 15px;
  color: #111;
  text-align: center;

  visibility: hidden;
  opacity: 0;
  transition: 0.3s ease-in-out;
}

.container .cards:hover .content {
  margin-top: 30px;
  visibility: visible;
  opacity: 1;
  transition-delay: 0.2s;
}

// ===================================Charts====================================>

#container {
  height: 500px;
}

.highcharts-figure,
.highcharts-data-table table {
  min-width: 320px;
  max-width: 700px;
  margin: 1em auto;
}

.highcharts-data-table table {
  font-family: Verdana, sans-serif;
  border-collapse: collapse;
  border: 1px solid #ebebeb;
  margin: 10px auto;
  text-align: center;
  width: 100%;
  max-width: 500px;
}

.highcharts-data-table caption {
  padding: 1em 0;
  font-size: 1.2em;
  color: #555;
}

.highcharts-data-table th {
  font-weight: 600;
  padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
  padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
  background: #f8f8f8;
}

.highcharts-data-table tr:hover {
  background: #f1f7ff;
}

// ================================= Contact Us ======================================

.formHaderBG {
  background-color: $formHeaderBg;
}

.iconColor {
  color: $formHeaderBg;
}

.Formbg {
  outline: none;
  border: 4px;
  box-shadow: 0 0 10px #1aa48b;
}

// ====================================Custom Card Careers==============================>

.card-bg {
  background-image: $landing-pg-intro-color;
}

.innerCardbg {
  background-color: rgba(0, 0, 0, 0.151);
}

.neon-bg {
  outline: none;
  border: 4px;
  border-color: #0e1012;
  box-shadow: 0 0 10px #0e1012;
}

.neon-bg1 {
  outline: none;
  border: 4px;
  border-color: #f3f9ff;
  box-shadow: 0 0 10px #ffffff;
}

.box {
  width: 20rem;
  margin-top: 24rem;
  left: 12rem;
}

.imgResponse {
  margin-left: 48px;
}

@media (max-width: 580px) {
  .box {
    width: 20rem;
    margin-top: 24rem;
    left: 4px;
  }

  .imgResponse {
    margin-left: 4px;
  }
}

// ==================================Custom Card home=====================================>

.cardBg {
  background-image: $landing-pg-intro-color;
}

.floating {
  animation-name: floating;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floating {
  from {
    transform: translate(0, 0px);
  }

  65% {
    transform: translate(0, 10px);
  }

  to {
    transform: translate(0, 0px);
  }
}

.animate-reveal {
  opacity: 0;
  -webkit-animation: reveal 2s cubic-bezier(0, 1, 0.5, 1) 1 normal forwards;
  -moz-animation: reveal 2s ease-in 1 normal forwards;
  -o-animation: reveal 2s cubic-bezier(0, 1, 0.5, 1) 1 normal forwards;
  animation: reveal 2s cubic-bezier(0, 1, 0.5, 1) 1 normal forwards;
}

.animate-first {
  -webkit-animation-delay: 0;
  -moz-animation-delay: 0;
  -o-animation-delay: 0;
  animation-delay: 0;
}

.animate-second {
  -webkit-animation-delay: 500ms;
  -moz-animation-delay: 500ms;
  -o-animation-delay: 500ms;
  animation-delay: 500ms;
}

.animate-third {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  -o-animation-delay: 1s;
  animation-delay: 1s;
}

@-webkit-keyframes reveal {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -o-transform: translateY(100%);
    transform: translateY(100%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

@-moz-keyframes reveal {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -o-transform: translateY(100%);
    transform: translateY(100%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

@-o-keyframes reveal {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -o-transform: translateY(100%);
    transform: translateY(100%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes reveal {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -o-transform: translateY(100%);
    transform: translateY(100%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

//   ======================================custom card services============================>

* {
  box-sizing: border-box;
}

.cardStyle {
  height: 360px;
  border-radius: 15px;
  padding: 1.5rem;
  background: white;
  position: relative;
  display: flex;
  align-items: flex-end;
  transition: 0.4s ease-out;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
}

.cardStyle:hover {
  transform: translateY(20px);
}

.cardStyle:hover:before {
  opacity: 1;
}

.cardStyle:hover .info {
  opacity: 1;
  transform: translateY(0px);
}

.cardStyle:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
  transition: 0.5s;
  opacity: 0;
}

.cardStyle img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 15px;
}

.cardStyle .info {
  position: relative;
  z-index: 3;
  color: white;
  opacity: 0;
  transform: translateY(30px);
  transition: 0.5s;
}

.cardStyle .info h1 {
  margin: 0px;
}

.cardStyle .info p {
  letter-spacing: 1px;
  font-size: 15px;
  margin-top: 8px;
}

.cardStyle .info button {
  padding: 0.6rem;
  outline: none;
  border: none;
  border-radius: 3px;
  background: white;
  color: black;
  font-weight: bold;
  cursor: pointer;
  transition: 0.4s ease;
}

.cardStyle .info button:hover {
  background: dodgerblue;
  color: white;
}

//   =============================== custom tabs home ==========================================>

.rd {
  background-color: white;
  border-radius: 200px 0px 0px 200px;
}

.cardbg {
  background-image: $custom-tab-color;
}

.btnS {
  background-color: $button-color !important;
}

.tabBtn {
  background-color: rgba(245, 222, 179, 0.384);
}

// =================================Footer=======================================>

.footerBg {
  background-image: $footer-bg-color;
  opacity: $footer-bg-opacity;
}

.footerBg {
  background-image: $footer-bg-color;
  opacity: $footer-bg-opacity;
}

.footerLink {
  overflow: hidden;
  position: relative;
  display: inline-block;
}

.footerLink ::before,
.footerLink ::after {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
}

.footerLink ::before {
  height: 2px;
  bottom: 0;
  transform-origin: 100% 50%;
  transform: scaleX(0);
  transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
}

.footerLink ::after {
  content: attr(data-replace);
  height: 100%;
  top: 0;
  transform-origin: 100% 50%;
  transform: translate3d(200%, 0, 0);
  transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
  color: #000000;
}

.footerLink :hover::before {
  transform-origin: 0% 50%;
  transform: scaleX(1);
}

.footerLink :hover::after {
  transform: translate3d(0, 0, 0);
}

.footerLink span {
  display: inline-block;
  transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
}

.footerLink :hover span {
  transform: translate3d(-200%, 0, 0);
}

a {
  text-decoration: none;
  color: #18272f;
  font-weight: 700;
  vertical-align: top;
}

// =================================Header=================================================>

.navLinkHover:hover {
  color: #00324c !important;
  transform: scale(1.04);
  transition: 0.1s ease-in;
}

.topFixed {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.navBg {
  background-color: #1aa48bf7;
  transition: 0.4s ease-in;
  height: 4rem;
  box-shadow: 4px 2px 10px #000;
}

.navtranP {
  background-color: #00000000;
  transition: 0.4s ease-out;
  height: 4rem;
}

@media (max-width: 580px) {
  .topFixed {
    position: relative;
  }
}

$min-width: 120px;

@mixin transform($value) {
  -webkit-transform: ($value);
  -moz-transform: ($value);
  -ms-transform: ($value);
  transform: ($value);
}

@mixin transition($value) {
  -webkit-transition: ($value);
  -moz-transition: ($value);
  -ms-transition: ($value);
  transition: ($value);
}

* {
  box-sizing: border-box;
}

#main {
  position: relative;
  list-style: none;
  font-weight: 400;
  font-size: 0;
  text-transform: uppercase;
  display: inline-block;
  padding: 0;

  li {
    font-size: 0.9rem;
    display: inline-block;
    position: relative;
    padding: 3px 18px;
    cursor: pointer;
    z-index: 5;
    min-width: $min-width;
  }
}

li {
  margin: 0;
}

.drop {
  overflow: hidden;
  list-style: none;
  position: absolute;
  padding: 0;
  width: 100%;
  left: 0;
  top: 51px;
  border-radius: 4px;

  div {
    @include transform(translate(0, -100%));
    @include transition(all 0.5s 0.1s);
    position: relative;
  }

  li {
    display: block;
    width: 100%;
    color: white;
    background: $nav-link-color !important;
  }
}

#marker {
  // height:5px;
  background: $nav-link-color !important;
  position: absolute;
  bottom: 0;
  width: $min-width;
  z-index: 2;
  @include transition(all 0.35s);
}

@for $i from 1 through 6 {
  #main li:nth-child(#{$i}) {
    &:hover ul div {
      @include transform(translate(0, 0));
    }

    &:hover ~ #marker {
      @include transform(translate(#{(-1 + $i) * $min-width}, 0));
    }
  }
}

@media (max-width: 580px) {
  #marker {
    height: 0px;
    background: $nav-link-color !important;
    position: absolute;
    bottom: 0;
    width: $min-width;
    z-index: 2;
    @include transition(all 0.35s);
  }

  .drop {
    overflow: hidden;
    list-style: none;
    position: absolute;
    padding: 0;
    width: 100%;
    left: 0;
    top: 53.5px;

    div {
      @include transform(translate(0, -100%));
      @include transition(all 0.5s 0.1s);
      position: relative;
    }

    li {
      display: block;
      width: 100%;
      color: white;
      background: $nav-link-color !important;
    }
  }
}

//=================================Our Team============================================

.card-R-bg {
  outline: none;
  border: 4px;
  border-color: #ffffff;
  box-shadow: 0 0 10px #e6f2ff;
}

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.clear-fix:after {
  display: block;
  clear: both;
  content: "";
}

.img-responsive {
  max-width: 100%;
  height: 100%;
}

.card__collection {
  position: relative;
  display: block;
  overflow: hidden;
}

.card__collection .cards {
  width: 300px;
  height: 400px;
  display: block;
  background-size: cover;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  background-position: center;
  margin: 10px;
}

.card__collection .cards--one {
  backface-visibility: hidden;
}

.card__collection .cards--one:hover:after {
  bottom: -20px;
}

.card__collection .cards--one:hover:before {
  bottom: -10px;
}

.card__collection .cards--one:hover .cards--one__rect {
  left: 45%;
}

.card__collection .cards--one:hover .cards--one__rect--back {
  left: 50%;
}

.card__collection .cards--one::after {
  content: "";
  display: block;
  position: absolute;
  height: 70px;
  transform: rotate(-3deg);
  background: #e660e3;
  position: absolute;
  bottom: -80px;
  left: 0;
  right: -10px;
  z-index: 9;
  transition: all 0.2s ease-in;
  transition-delay: 0.3s;
}

.card__collection .cards--one:before {
  content: "";
  display: block;
  position: absolute;
  height: 80px;
  transform: rotate(-3deg);
  bottom: -90px;
  left: 0;
  background: #fff;
  right: -10px;
  z-index: 5;
  transition: all 0.2s ease-in;
  transition-delay: 0.1s;
}

.card__collection .cards--one__rect {
  color: #fff;
  text-transform: uppercase;
  font-size: 18px;
  background: #0f9bc0;
  width: 126px;
  height: 55px;
  transform: skewY(5deg);
  position: absolute;
  display: block;
  top: 60%;
  left: -45%;
  z-index: 1;
  line-height: 3.3rem;
  text-align: center;
  transition: all 0.2s ease-in;
}

.card__collection .cards--one__rect--back {
  display: block;
  background: rgba(34, 65, 154, 0.8);
  width: 126px;
  height: 55px;
  transform: skewY(7deg);
  position: absolute;
  top: 65%;
  left: -50%;
  transition: all 0.2s ease-in;
  transition-delay: 0.3s;
}

.card__collection .cards--one__rect p {
  transform: skewY(-7deg);
  position: relative;
}

.card__collection .cards--two {
  position: relative;
  backface-visibility: hidden;
}

.card__collection .cards--two p {
  position: absolute;
  top: 83%;
  left: -100%;
  text-transform: capitalize;
  color: #fff;
  font-size: 20px;
  z-index: 8;
  transition: all 0.6s ease;
}

.card__collection .cards--two:hover p {
  left: 8%;
}

.card__collection .cards--two:hover img {
  transform: translateY(-15px);
}

.card__collection .cards--two:hover .cards--two__rect {
  top: 75%;
}

.card__collection .cards--two:hover .cards--two__rect:before {
  transform: translateY(15px);
}

.card__collection .cards--two:hover li {
  transform: translateY(0);
}

.card__collection .cards--two:hover .cards--two__tri {
  right: -40%;
}

.card__collection .cards--two:hover .cards--two__tri:before {
  right: -312px;
}

.card__collection .cards--two img {
  transition: all 0.2s ease;
}

.card__collection .cards--two__tri {
  border-top: 220px solid transparent;
  border-bottom: 190px solid transparent;
  border-right: 288px solid #fff;
  opacity: 0.9;
  position: absolute;
  display: block;
  top: 0;
  right: -100%;
  transition: all 0.3s ease-in-out;
}

.card__collection .cards--two__tri:before {
  border-top: 220px solid transparent;
  border-bottom: 190px solid transparent;
  border-right: 288px solid #57ccfd;
  position: absolute;
  content: "";
  display: block;
  top: -220px;
  right: -612px;
  transition: all 0.3s ease-in-out;
  transition-delay: 0.2s;
}

.card__collection .cards--two__rect {
  width: 750px;
  height: 200px;
  background: #fff;
  display: block;
  position: absolute;
  top: 175%;
  left: -78%;
  transform: rotate(30deg);
  z-index: 5;
  opacity: 0.9;
  transition: all 0.3s ease-in-out;
}

.card__collection .cards--two__rect:before {
  content: "";
  display: block;
  width: 100%;
  position: relative;
  height: 100%;
  background: #f07306;
  transform: translateY(200px);
  z-index: 2;
  transition: all 0.3s ease-in-out;
  transition-delay: 0.1s;
}

.card__collection .cards--two ul {
  list-style: none;
  position: absolute;
  bottom: 0;
  left: 10px;
  z-index: 9;
}

.card__collection .cards--two ul li {
  display: inline-block;
  font-size: 16px;
  margin: 7px;
  color: #fff;
  transition: all 0.2s ease-in-out;
  transform: translateY(100px);
}

.card__collection .cards--two ul li:nth-child(2) {
  transition-delay: 0.2s;
}

.card__collection .cards--two ul li:nth-child(3) {
  transition-delay: 0.3s;
}

.card__collection .cards--two ul li:nth-child(4) {
  transition-delay: 0.4s;
}

.card__collection .cards--three {
  position: relative;
  z-index: 10;
}

.card__collection .cards--three:hover .cards--three__rect-1 {
  left: 10%;
}

.card__collection .cards--three:hover .cards--three__rect-1 .shadow-1 {
  left: -20%;
}

.card__collection .cards--three:hover .cards--three__rect-2 {
  left: 60%;
}

.card__collection .cards--three:hover .cards--three__rect-2:before {
  left: -100%;
}

.card__collection .cards--three:hover .cards--three__rect-2:after {
  left: 80%;
}

.card__collection .cards--three:hover .cards--three__rect-2 .shadow-2 {
  left: -10%;
}

.card__collection .cards--three:hover .cards--three__circle {
  transform: scale(1);
}

.card__collection .cards--three:hover .cards--three__circle:before {
  transform: scale(0.9);
}

.card__collection .cards--three:hover .cards--three__list li {
  transform: translateX(0);
}

.card__collection .cards--three__rect-1 {
  background: #fff;
  width: 200px;
  height: 110px;
  transform: skewX(-20deg);
  display: block;
  position: absolute;
  top: 73%;
  opacity: 0.9;
  left: -100%;
  z-index: 8;
  transition: all 0.5s ease-in;
}

.card__collection .cards--three__rect-1 p {
  font-size: 20px;
  color: #fff;
  transform: skewX(20deg);
  line-height: 6rem;
}

.card__collection .cards--three__rect-1 .shadow-1 {
  background: #1aa48b;
  width: 230px;
  height: 100px;
  display: block;
  position: absolute;
  left: -200%;
  z-index: -1;
  transition: all 0.3s ease-in;
}

.card__collection .cards--three__rect-2 {
  width: 100px;
  height: 70px;
  background: #fff;
  position: absolute;
  top: 65%;
  left: 1000%;
  opacity: 0.9;
  transform: skewX(-20deg);
  z-index: 8;
  transition: all 0.5s ease-in;
}

.card__collection .cards--three__rect-2::before {
  content: "";
  display: block;
  width: 50px;
  height: 40px;
  background: rgb(12, 0, 144);
  opacity: 1;
  position: absolute;
  left: 500%;
  top: 10%;
  transition: all 0.3s ease-in;
}

.card__collection .cards--three__rect-2::after {
  content: "";
  display: block;
  width: 50px;
  height: 40px;
  background: #1aa48b;
  opacity: 1;
  position: absolute;
  left: 500%;
  top: 114%;
  transition: all 0.5s ease-in;
  transition-delay: 0.2s;
}

.card__collection .cards--three__rect-2 .shadow-2 {
  background: rgb(12, 0, 144);
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: -10%;
  left: 500%;
  transition: all 0.5s ease-in;
  transition-delay: 0.2s;
}

.card__collection .cards--three__circle {
  width: 220px;
  height: 220px;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  top: -15%;
  left: 50%;
  opacity: 0.9;
  transform: scale(0);
  transition: all 0.3s ease;
}

.card__collection .cards--three__circle:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: #1aa48b;
  border-radius: 50%;
  transform: scale(0);
  transition: all 0.3s ease;
  transition-delay: 0.2s;
}

.card__collection .cards--three__list {
  list-style: none;
  position: absolute;
  top: -9px;
  right: 7px;
  padding-top: 60px;
  transition: all 0.4s ease;
}

.card__collection .cards--three__list li {
  display: inline-block;
  margin-right: 40px;
  color: #fff;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 1.7rem;
  font-size: 12px;
  transition: all 0.3s ease;
  transform: translateX(500%);
}

.card__collection .cards--three__list li:nth-child(1) {
  transition-delay: 0.2s;
}

.card__collection .cards--three__list li:nth-child(2) {
  transition-delay: 0.3s;
}

.card__collection .cards--three__list li:nth-child(3) {
  transition-delay: 0.4s;
}

//=================================Services Comp========================================>

.item-1,
.item-2,
.item-3 {
  position: absolute;
  display: block;
  width: 80%;

  animation-duration: 20s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.item-1 {
  animation-name: anim-1;
}

.item-2 {
  animation-name: anim-2;
}

.item-3 {
  animation-name: anim-3;
}

@keyframes anim-1 {
  0%,
  8.3% {
    left: -100%;
    opacity: 0;
  }

  8.3%,
  25% {
    left: 10%;
    opacity: 1;
  }

  33.33%,
  100% {
    left: 25%;
    opacity: 0;
  }
}

@keyframes anim-2 {
  0%,
  33.33% {
    left: -100%;
    opacity: 0;
  }

  41.63%,
  58.29% {
    left: 10%;
    opacity: 1;
  }

  66.66%,
  100% {
    left: 25%;
    opacity: 0;
  }
}

@keyframes anim-3 {
  0%,
  66.66% {
    left: -50%;
    opacity: 0;
  }

  74.96%,
  91.62% {
    left: 10%;
    opacity: 1;
  }

  100% {
    left: 25%;
    opacity: 0;
  }
}

@media (max-width: 580px) {
  .imghide {
    visibility: hidden;
    display: none;
  }

  .item-1,
  .item-2,
  .item-3 {
    position: absolute;
    display: block;
    width: 80%;

    animation-duration: 20s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }

  .item-1 {
    animation-name: anim-1;
  }

  .item-2 {
    animation-name: anim-2;
  }

  .item-3 {
    animation-name: anim-3;
  }

  @keyframes anim-1 {
    0%,
    8.3% {
      left: -100%;
      opacity: 0;
    }

    8.3%,
    25% {
      left: 25%;
      opacity: 1;
    }

    33.33%,
    100% {
      left: 25%;
      opacity: 0;
    }
  }

  @keyframes anim-2 {
    0%,
    33.33% {
      left: -100%;
      opacity: 0;
    }

    41.63%,
    58.29% {
      left: 25%;
      opacity: 1;
    }

    66.66%,
    100% {
      left: 25%;
      opacity: 0;
    }
  }

  @keyframes anim-3 {
    0%,
    66.66% {
      left: -50%;
      opacity: 0;
    }

    74.96%,
    91.62% {
      left: 25%;
      opacity: 1;
    }

    100% {
      left: 25%;
      opacity: 0;
    }
  }
}

//=======================================serveses2===========================================>

.card-Style {
  outline: none;
  border: 0.5px;
  border-color: #ffffff;
  box-shadow: 0 0 10px #1aa48b;
}

.card1 {
  display: block;
  position: relative;
  background-color: #f2f8f9;
  border-radius: 4px;
  padding: 32px 24px;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
}

.card1:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -16px;
  right: -16px;
  background: #1aa48b;
  height: 32px;
  width: 32px;
  border-radius: 32px;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 0.8s ease-out;
}

.card1:hover:before {
  transform: scale(70);
}

.card1:hover p {
  transition: all 0.3s ease-out;
  color: rgba(255, 255, 255, 0.8);
}

.card1:hover h3 {
  transition: all 0.3s ease-out;
  color: #ffffff;
}

// =========================================client Marquee====================================>

// @import url("https://css.gg/css");

// .Marquees {
//   overflow-x: hidden;
// }

// .Marquee {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   width: 200vw;
//   height: 13vh;
// }

// .marquee {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   background: #1AA48B;
//   box-shadow: 5px 5px 20px #00000050, -5px -5px 15px #ffffff0a;
//   height: 11vh;
//   width: 12.5vw;
//   margin: 10px;
//   border-radius: 15px;
// }

// .marquee:hover {
//   background: #1d1d1d;
// }

// .icon {
//   color: #cccccc;
//   transform: scale(1.5);
// }

// .FirstRow {
//   animation: Scroll 60s linear infinite;
// }

// .SecondRow {
//   animation: Scroll 60s linear infinite;
//   animation-direction: reverse;
// }

// @media(max-width : 580px) {
//   .marquee {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     background: #00000000;

//   }
// }

// @keyframes Scroll {
//   0% {
//     transform: translateX(-50vw);
//     -webkit-transform: translateX(-50vw);
//   }

//   100% {
//     transform: translateX(50vw);
//     -webkit-transform: translateX(50vw);
//   }
// }

// ======================marquee second=========================================>

.marquee-full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}
.marquee-box {
  overflow-x: hidden !important;
  display: flex !important;
  flex-direction: row !important;
  position: relative;
  width: 100%;
  // background-color: white;
  border-radius: 50px;
}
.marquee {
  flex: 0 0 auto;
  min-width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  animation: marqueescroll 100s linear 0s infinite;
  animation-play-state: running;
  animation-delay: 0s;
  animation-direction: normal;
}
.marquee figure {
  margin-right: 3rem;
  margin-left: 3rem;
  padding: 5px;
  max-width: 250px;
  text-align: center;
  vertical-align: top;
}
.marquee figcaption {
  display: none;
  font-weight: 80%;
  color: #333;
  line-height: 1.2;
}
.marquee img {
  margin: 0 auto;
  height: auto;
  max-height: 3.5rem;
  max-width: 100%;
  border: none;
}

.marquee-box:hover div {
  animation-play-state: running;
  background-color: white;
  transition: 0.4s ease-in;
}

.marquee-box:active div {
  animation-play-state: running;
}
@keyframes marqueescroll {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

// .........................Animated background .............................

@import url("https://fonts.googleapis.com/css?family=Exo:400,700");

* {
  margin: 0px;
  padding: 0px;
}

.circles {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 25s linear infinite;
  bottom: -26px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 0s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 5px;
  height: 5px;
  animation-delay: 0s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 0s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 0s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

@media (max-width: 580px) {
  .circles {
    display: none;
  }
}

//====================================Carousel icon style ==================================>

.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 100px;
  width: 100px;
  outline: black;
  background-size: 100%, 100%;
  border-radius: 50%;

  background-image: none;
}

.carousel-control-next-icon:after {
  content: ">";
  font-size: 55px;
  color: red;
}

.carousel-control-prev-icon:after {
  content: "<";
  font-size: 55px;
  color: red;
}

.delete-card {
  height: 300px;
}
